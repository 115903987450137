<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        ref="refBranchListTable"
        class="position-relative"
        :items="branches"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        style="min-height: 150px"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <div v-if="$route.name === 'retailers-management-catalogue-management-retailer-catalogue-list' && $ability.can('C', 'Retailer Catalogue Management')">
              <b-dropdown-item :to="{ name: 'retailers-management-catalogue-management-retailer-catalogue-create', params: { id: data.item.branchId } }">
                <feather-icon icon="UploadCloudIcon" />
                <span class="align-middle ml-50">Upload Catalogue</span>
              </b-dropdown-item>
            </div>

            <div v-else>
              <b-dropdown-item
                v-if="$ability.can('V', 'Retailers & Branches')"
                :to="{ name: 'retailers-management-retailer-branches-view', params: { id: data.item.branchId } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$ability.can('E', 'Retailers & Branches')"
                :to="{ name: 'retailers-management-retailer-branches-edit', params: { id: data.item.branchId } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$ability.can('D', 'Retailers & Branches')"
                @click="procBranch({ operation: 'D', branch_id: data.item.branchId })"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>

        <!-- Column: Activity -->
        <template #cell(activity)="data">
          {{ data.item.activity.typ_desc }}
        </template>

        <!-- Column: Sub Activity -->
        <template #cell(subactivity)="data">
          {{ data.item.subactivity.cat_desc }}
        </template>

        <!-- Column: Catalogue -->
        <template #cell(catalogue)="data">
          <b-link
            v-if="$ability.can('V', 'Retailer Catalogue Management')"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
            :to="{ name: 'retailers-management-catalogue-management-retailer-catalogue-view', params: { id: data.item.branchId } }"
          >
            <span class="text-capitalize">Catalogue</span>
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <devices-list
            v-if="$route.name === 'retailers-management-retailer-subscriptions-edit'"
            :table-columns="deviceTableColumns"
            :branch-id="data.item.branchId"
            :devices="data.item.products"
            @initiate-delete="initiateDelete"
            @initiate-edit="initiateEdit"
            @initiate-add="initiateAdd"
          />

          <cashiers-list
            v-else
            :cashiers="data.item.cashiers"
          />
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BDropdown, BDropdownItem, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'
import CashiersList from '../../cashiers/cashiers-list/CashiersList.vue'
import DevicesList from '../../devices/devices-list/DevicesList.vue'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BLink,
    BButton,

    CashiersList,
    DevicesList,
  },
  directives: {
    Ripple,
  },
  props: {
    branches: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    deviceTableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(_, { emit }) {
    const procBranch = params => {
      store.dispatch('app-retailer/procBranch', params)
      emit('refetch-data')
    }

    const initiateDelete = payload => {
      emit('initiate-delete', payload)
    }

    const initiateEdit = payload => {
      emit('initiate-edit', payload)
    }

    const initiateAdd = payload => {
      emit('initiate-add', payload)
    }

    return {
      resolveStatus,
      resolveStatusVariant,

      procBranch,

      initiateDelete,
      initiateEdit,
      initiateAdd,
    }
  },
}
</script>
