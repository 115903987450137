export default function useBranchesList() {
  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'name', sortable: true },
    { key: 'CRNumber', sortable: true, label: 'CR Number' },
    { key: 'activity', sortable: true },
    { key: 'subactivity', sortable: true },
    { key: 'catalogue' },
    { key: 'status', sortable: true },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  return {
    tableColumns,
  }
}
